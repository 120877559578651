import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";
import ContactSection from "../components/contact";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Header />

      <main
        id="layout"
        className="mt-20 md:mt-12 flex-1 w-full max-w-7xl mx-auto px-4 sm:px-6 py-8 md:px-8 md:py-16"
      >
        {children}
      </main>

      <footer className=" bg-gradient-to-tr from-purple-800 to-pink-600">
        <ContactSection />
        <Footer />
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
