import React, { useState } from "react";
import PropTypes from "prop-types";

// title - title of the menu,  menuItems - array of the objects of menu items and their links
function CollapsableMenu({ title, menuItems }) {
  //, outerClick
  //{ title, menuItems }

  const [isActive, setActive] = useState(false);
  // const [isOutsideClicked, setOutside] = useState(outerClick);
  // const clickOutside = function(e) {
  //   console.log(e.target)
  // }
  // console.log(isOutsideClicked)
  return (
    <div
      onMouseEnter={() => {
        setActive(true);
      }}
      onMouseLeave={() => {
        setActive(false);
      }}
      className="relative"
      id="collapsable"
    >
      <button
        id="serviceButton"
        type="button"
        className={`group h-auto w-auto rounded-md text-gray-900 inline-flex items-center text-base font-medium hover:hover:text-brandOrange-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      >
        <span>{title}</span>
        <svg
          className="ml-2 h-5 w-5 text-gray-900 group-hover:hover:text-brandOrange-900"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {/*
      'Solutions' flyout menu, show/hide based on flyout menu state.

      Entering: "transition ease-out duration-200"
        From: "opacity-0 translate-y-1"
        To: "opacity-100 translate-y-0"
      Leaving: "transition ease-in duration-150"
        From: "opacity-100 translate-y-0"
        To: "opacity-0 translate-y-1"
    */}
      <div
        className={`${
          isActive ? `block h-auto opacity-100 z-10` : `hidden opacity-0 -z-10` //h-0
        } transition ease-out duration-300 fixed z-10 -ml-4 pt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2`} //absolute
      >
        <div className=" rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div
            className={`relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8`}
          >
            {menuItems.map((item) => (
              <a
                key={item.title}
                href={item.link}
                className="-m-3 p-3 flex items-start rounded-lg hover:hover:text-brandOrange-900"
              >
                {/* Heroicon name: chart-bar */}
                {item.Svg}
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">
                    {item.title}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    {item.description}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

CollapsableMenu.propTypes = {
  title: PropTypes.string,
  menuItems: PropTypes.array,
  outerClick: PropTypes.bool,
};

export default CollapsableMenu;
