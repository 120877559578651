// import { graphql, useStaticQuery } from "gatsby"; //link
import React, { useState } from "react";
import PropTypes from "prop-types";

import Collapsable from "./header/Collapsable";

import logo from "../svg/logo-full.svg";

function Header({ outerClick }) {
  // const [menus, closeMenus] = use

  const serviceItems = [
    {
      title: "Surveillance System",
      //className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
      // aria-hidden="true"
      Svg: (
        <svg
          className="ml-2 h-6 w-6 text-gray-400 group-hover:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 128 128"
        >
          <path
            fill="#17ace8"
            d="M28.345 30.863l68.68 39.654-5.977 10.353a8 8 0 01-10.928 2.928L25.296 52.144a8 8 0 01-2.928-10.928l5.977-10.353z"
          ></path>
          <path
            fill="#0d70b2"
            d="M48.254 12.382l77.993 45.031-12.98 22.482-84.921-49.031 8.98-15.554a8 8 0 0110.928-2.928z"
          ></path>
          <path
            fill="#fedb41"
            d="M91.051 80.871a8.127 8.127 0 01-.574.848c.022.013.04.028.061.04a12.98 12.98 0 0017.731-4.751l-11.241-6.49z"
          ></path>
          <path
            fill="#00ddc1"
            d="M104.207 59.675a4.98 4.98 0 106.8-1.823 4.98 4.98 0 00-6.8 1.823z"
          ></path>
          <path
            fill="#e1e4fb"
            d="M44.627 53.537a1.74 1.74 0 01-.873-.235L29.41 45.02a1.75 1.75 0 111.75-3.031L45.5 50.27a1.751 1.751 0 01-.877 3.267z"
          ></path>
          <path
            fill="#0d70b2"
            d="M1.75 57.373H7a6 6 0 016 6v47.317a6 6 0 01-6 6H1.75V57.373z"
          ></path>
          <path fill="#c5c9f7" d="M13 67.381h11.25v36H13z"></path>
          <path
            fill="#e1e4fb"
            d="M53.567 68.467l-5.051 8.612a8.034 8.034 0 01-6.9 3.953H24.25v12h17.364a20.078 20.078 0 0017.252-9.881l5.093-8.684z"
          ></path>
        </svg>
      ),
      link: "/services/#surveillance-cctv",
      description:
        "Surveillance System, CCTV - outdoor & indoor, Biometric Systems",
    },
    {
      title: "Networking Systems",
      Svg: (
        <svg
          aria-hidden="true"
          className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          enableBackground="new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
        >
          <path
            fill="#455A64"
            d="M373.333 322.532c-5.891 0-10.667-4.776-10.667-10.667V162.532c0-5.891 4.776-10.667 10.667-10.667S384 156.641 384 162.532v149.333c0 5.891-4.776 10.667-10.667 10.667z"
          ></path>
          <path
            fill="#CFD8DC"
            d="M53.333 301.177h405.333c29.455 0 53.333 23.878 53.333 53.333v42.667c0 29.455-23.878 53.333-53.333 53.333H53.333C23.878 450.511 0 426.632 0 397.177V354.51c0-29.455 23.878-53.333 53.333-53.333z"
          ></path>
          <g fill="#4CAF50">
            <circle cx="74.667" cy="375.865" r="10.667"></circle>
            <circle cx="138.667" cy="375.865" r="10.667"></circle>
            <circle cx="202.667" cy="375.865" r="10.667"></circle>
            <circle cx="266.667" cy="375.865" r="10.667"></circle>
          </g>
          <path
            fill="#455A64"
            d="M437.333 386.532H330.667c-5.891 0-10.667-4.776-10.667-10.667s4.776-10.667 10.667-10.667h106.667c5.891 0 10.667 4.776 10.667 10.667s-4.777 10.667-10.668 10.667z"
          ></path>
          <g fill="#2196F3">
            <path d="M343.168 203.385a10.663 10.663 0 01-7.552-3.115c-20.831-20.825-20.835-54.594-.01-75.425l.01-.01c4.093-4.237 10.845-4.354 15.083-.262 4.237 4.093 4.354 10.845.262 15.083-.086.089-.173.176-.262.262-12.501 12.493-12.507 32.754-.014 45.255l.014.014c4.171 4.16 4.179 10.914.019 15.085a10.667 10.667 0 01-7.571 3.134l.021-.021zM403.499 203.385a10.667 10.667 0 01-7.552-18.219c12.501-12.493 12.507-32.754.014-45.255l-.014-.014c-4.093-4.237-3.975-10.99.262-15.083a10.666 10.666 0 0114.821 0c20.831 20.825 20.835 54.594.01 75.425l-.01.01a10.667 10.667 0 01-7.531 3.136z"></path>
            <path d="M312.981 233.551a10.663 10.663 0 01-7.552-3.115c-37.432-37.515-37.432-98.251 0-135.765a10.668 10.668 0 0115.083 15.083c-29.161 29.158-29.163 76.434-.005 105.595l.005.005c4.171 4.16 4.179 10.914.019 15.085a10.667 10.667 0 01-7.571 3.134l.021-.022zM433.685 233.551a10.667 10.667 0 01-7.552-18.219c29.161-29.158 29.163-76.434.005-105.595l-.005-.005c-4.093-4.237-3.975-10.99.262-15.083a10.666 10.666 0 0114.821 0c37.432 37.515 37.432 98.251 0 135.765a10.67 10.67 0 01-7.531 3.137z"></path>
            <path d="M282.816 263.716a10.663 10.663 0 01-7.552-3.115c-54.147-54.152-54.147-141.944 0-196.096 4.237-4.093 10.99-3.975 15.083.262 3.992 4.134 3.992 10.687 0 14.82-45.819 45.821-45.819 120.11 0 165.931 4.171 4.16 4.179 10.914.019 15.085a10.667 10.667 0 01-7.571 3.134l.021-.021zM463.851 263.716a10.667 10.667 0 01-7.552-18.219c45.819-45.821 45.819-120.11 0-165.931-4.093-4.237-3.976-10.99.262-15.083a10.666 10.666 0 0114.821 0c54.147 54.152 54.147 141.944 0 196.096a10.664 10.664 0 01-7.531 3.137z"></path>
          </g>
        </svg>
      ),
      link: "/services/#networking-system",
      description:
        "large area WiFi zone, point to point (p to p), Fiber optics",
    },
    {
      title: "Security Systems",
      Svg: (
        <svg
          className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="512"
          height="512"
          viewBox="0 0 24 24"
        >
          <path
            fill="#f44336"
            d="M12 18.75c0-1.437.812-2.687 2-3.316a4.503 4.503 0 013.533-4.322c-.551-2.063-1.502-3.881-2.384-5.548-.951-1.796-1.85-3.493-1.984-5.104a.5.5 0 00-.777-.373C7.25 3.554 7.329 8.551 7.717 10.914 6.115 9.478 5.487 7.386 5.48 7.36a.498.498 0 00-.33-.337.507.507 0 00-.463.086C4.578 7.197 2 9.319 2 14.5c0 4.416 3.29 7.5 8 7.5a9.83 9.83 0 002-.207z"
          ></path>
          <path
            fill="#d43a2f"
            d="M12 .358C7.254 3.81 7.339 8.612 7.717 10.914 6.115 9.478 5.487 7.386 5.48 7.36A.498.498 0 005.001 7a.508.508 0 00-.314.109C4.578 7.197 2 9.319 2 14.5c0 4.416 3.29 7.5 8 7.5a9.83 9.83 0 002-.207V18.75z"
          ></path>
          <path
            fill="#607d8b"
            d="M21.25 18.25a.75.75 0 01-.75-.75v-2a1 1 0 00-2 0v2a.75.75 0 01-1.5 0v-2c0-1.379 1.121-2.5 2.5-2.5s2.5 1.121 2.5 2.5v2a.75.75 0 01-.75.75z"
          ></path>
          <path
            fill="#ffc107"
            d="M22.25 17h-5.5c-.965 0-1.75.785-1.75 1.75v3.5c0 .965.785 1.75 1.75 1.75h5.5c.965 0 1.75-.785 1.75-1.75v-3.5c0-.965-.785-1.75-1.75-1.75z"
          ></path>
          <path
            fill="#546d79"
            d="M19.5 13a2.502 2.502 0 00-2.5 2.5V17h1.5v-1.5a1 1 0 011-1z"
          ></path>
          <path
            fill="#dea806"
            d="M19.5 17h-2.75c-.965 0-1.75.785-1.75 1.75v3.5c0 .965.785 1.75 1.75 1.75h2.75z"
          ></path>
        </svg>
      ),
      link: "/services/#fire-security-system",
      description: "Fire Alarm Systems, Intruder Alarm Systems",
    },
    {
      title: "Website Development",
      Svg: (
        <svg
          className="ml-2 h-6 w-12 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512.003 512.003"
        >
          <path
            fill="#5bc980"
            d="M121.021 270.004c-.517 0-1.037-.027-1.562-.081-8.241-.852-14.229-8.224-13.378-16.464 6.767-65.408 28.226-129.786 62.059-186.174 4.262-7.103 13.473-9.408 20.58-5.145 7.104 4.262 9.407 13.476 5.145 20.58-31.589 52.648-51.625 112.756-57.941 173.826-.799 7.716-7.313 13.458-14.903 13.458z"
          ></path>
          <path
            fill="#6aa9ff"
            d="M121.008 270.003l-.27-.002c-8.282-.146-14.879-6.979-14.733-15.262a1152.237 1152.237 0 0130.396-243.171c1.897-8.064 9.973-13.062 18.037-11.166 8.064 1.898 13.063 9.973 11.166 18.037A1122.19 1122.19 0 00136 255.268c-.144 8.192-6.831 14.735-14.992 14.735z"
          ></path>
          <path
            fill="#ffb64c"
            d="M120.984 270.004c-7.591 0-14.104-5.742-14.902-13.458-6.316-61.07-26.353-121.178-57.941-173.826-4.263-7.104-1.959-16.318 5.145-20.58 7.104-4.264 16.318-1.958 20.58 5.145 33.833 56.388 55.292 120.766 62.059 186.174.852 8.24-5.137 15.612-13.378 16.464-.525.055-1.047.081-1.563.081z"
          ></path>
          <path
            fill="#ff6c6c"
            d="M120.998 270.003c-8.162 0-14.849-6.543-14.993-14.736A1122.068 1122.068 0 0076.401 18.438C74.504 10.375 79.503 2.3 87.567.402c8.064-1.896 16.14 3.101 18.037 11.166C124.333 91.16 134.559 172.974 136 254.739c.146 8.283-6.451 15.116-14.733 15.262l-.269.002z"
          ></path>
          <path
            fill="#eff6fe"
            d="M316.003 113.783l-14.56 1.55 14.56 40.89v47.56l-11.44 7.33 10.49 34.16c-2.14 5.68-7.62 9.73-14.05 9.73h-60c-8.28 0-15-6.72-15-15v-83.78l9.44-11.78-9.44-30.66v-47.56l9.44-18-9.44-24.44v-8.78c0-8.28 6.72-15 15-15h60c8.28 0 15 6.72 15 15z"
          ></path>
          <path
            fill="#d2dce7"
            d="M316.003 113.783l-14.56 1.55 14.56 40.89v47.56l-11.44 7.33 10.49 34.16c-2.14 5.68-7.62 9.73-14.05 9.73h-30v-255h30c8.28 0 15 6.72 15 15z"
          ></path>
          <path
            fill="#d2dce7"
            d="M316.003 113.783v42.44l-90-90v-42.44zM316.003 203.783v36.22c0 1.85-.34 3.63-.95 5.27l-89.05-89.05v-42.44z"
          ></path>
          <path
            fill="#a1aebc"
            d="M316.003 113.783v42.44l-45-45v-42.44zM271.003 158.783l45 45v36.22c0 1.85-.34 3.63-.95 5.27l-44.05-44.05z"
          ></path>
          <path
            fill="#fed843"
            d="M436.003 15.003v240c0 8.28-6.72 15-15 15s-15-6.72-15-15v-240c0-8.28 6.72-15 15-15s15 6.72 15 15z"
          ></path>
          <path
            fill="#ffb64c"
            d="M436.003 15.003v240c0 8.28-6.72 15-15 15v-270c8.28 0 15 6.72 15 15z"
          ></path>
          <path
            fill="#6e6e6e"
            d="M451.003 240.003h-60c-8.28 0-15 6.72-15 15v242c0 8.28 6.72 15 15 15h60c8.28 0 15-6.72 15-15v-242c0-8.28-6.72-15-15-15z"
          ></path>
          <path
            fill="#444"
            d="M466.003 255.003v242c0 8.28-6.72 15-15 15h-30v-272h30c8.28 0 15 6.72 15 15z"
          ></path>
          <path
            fill="#6e6e6e"
            d="M151.003 240.003h-60c-8.28 0-15 6.72-15 15v242c0 8.28 6.72 15 15 15h60c8.28 0 15-6.72 15-15v-242c0-8.28-6.72-15-15-15z"
          ></path>
          <path
            fill="#444"
            d="M166.003 255.003v242c0 8.28-6.72 15-15 15h-30v-272h30c8.28 0 15 6.72 15 15z"
          ></path>
          <path
            fill="#6e6e6e"
            d="M331.003 240.003h-120c-8.28 0-15 6.72-15 15v242c0 8.28 6.72 15 15 15h120c8.28 0 15-6.72 15-15v-242c0-8.28-6.72-15-15-15z"
          ></path>
          <path
            fill="#444"
            d="M346.003 255.003v242c0 8.28-6.72 15-15 15h-60v-272h60c8.28 0 15 6.72 15 15z"
          ></path>
        </svg>
      ),
      link: "/services/#website-development",
      description:
        "We offer Developement work for static, dynamic and hybrid web applications.",
    },
    {
      title: "Intercom System",
      Svg: (
        <svg
          className="ml-2 h-5 w-6 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="512"
          height="512"
          viewBox="0 0 512 512"
        >
          <path
            fill="#e8e8e8"
            d="M481.095 109.199c17.069 0 30.905 13.837 30.905 30.905v293.602c0 17.069-13.837 30.905-30.905 30.905H30.905C13.837 464.612 0 450.775 0 433.706V140.105C0 123.036 13.837 109.2 30.905 109.2h450.19z"
          ></path>
          <path
            fill="#c9c9c9"
            d="M512 140.105v293.602c0 17.07-13.835 30.905-30.905 30.905H450.19c17.07 0 30.905-13.835 30.905-30.905V140.105c0-17.07-13.835-30.905-30.905-30.905h30.905c17.07-.001 30.905 13.835 30.905 30.905z"
          ></path>
          <path
            fill="#b7b7b7"
            d="M445.038 237.972H227.67c-11.379 0-20.604-9.225-20.604-20.604V67.992c0-11.379 9.225-20.604 20.604-20.604h217.368c11.379 0 20.604 9.225 20.604 20.604v149.376c0 11.379-9.225 20.604-20.604 20.604z"
          ></path>
          <path
            fill="#bde0f2"
            d="M429.586 207.066H243.123a5.15 5.15 0 01-5.151-5.151V83.445a5.15 5.15 0 015.151-5.151h186.463a5.15 5.15 0 015.151 5.151v118.471a5.152 5.152 0 01-5.151 5.15z"
          ></path>
          <path
            fill="#b7b7b7"
            d="M165.859 237.972H41.207a5.15 5.15 0 01-5.151-5.151v-82.414a5.15 5.15 0 015.151-5.151h124.652a5.15 5.15 0 015.151 5.151v82.414a5.15 5.15 0 01-5.151 5.151z"
          ></path>
          <path
            fill="#fcd87e"
            d="M165.859 304.934H41.207a5.15 5.15 0 01-5.151-5.151v-20.604a5.15 5.15 0 015.151-5.151h124.652a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.151z"
          ></path>
          <path
            fill="#e8bb56"
            d="M171.01 279.179v20.604a5.153 5.153 0 01-5.151 5.151h-30.905a5.153 5.153 0 005.151-5.151v-20.604a5.153 5.153 0 00-5.151-5.151h30.905a5.153 5.153 0 015.151 5.151z"
          ></path>
          <g fill="#7f7f93">
            <path d="M253.94 304.934h-20.604a5.15 5.15 0 01-5.151-5.151v-20.604a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.152 5.152 0 01-5.151 5.151zM315.75 304.934h-20.604a5.15 5.15 0 01-5.151-5.151v-20.604a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.151zM377.561 304.934h-20.604a5.15 5.15 0 01-5.151-5.151v-20.604a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.151zM439.372 304.934h-20.604a5.15 5.15 0 01-5.151-5.151v-20.604a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.151zM253.94 366.744h-20.604a5.15 5.15 0 01-5.151-5.151V340.99a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.15zM315.75 366.744h-20.604a5.15 5.15 0 01-5.151-5.151V340.99a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.15 5.15 0 01-5.151 5.15zM377.561 366.744h-20.604a5.15 5.15 0 01-5.151-5.151V340.99a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.15 5.15 0 01-5.151 5.15zM439.372 366.744h-20.604a5.15 5.15 0 01-5.151-5.151V340.99a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.15 5.15 0 01-5.151 5.15zM253.94 428.555h-20.604a5.15 5.15 0 01-5.151-5.151V402.8a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.151zM315.75 428.555h-20.604a5.15 5.15 0 01-5.151-5.151V402.8a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.15 5.15 0 01-5.151 5.151z"></path>
          </g>
          <path
            fill="#f49595"
            d="M439.372 397.65h-82.414a5.15 5.15 0 00-5.151 5.151v20.604a5.15 5.15 0 005.151 5.151h82.414a5.15 5.15 0 005.151-5.151v-20.604a5.15 5.15 0 00-5.151-5.151z"
          ></path>
          <path
            fill="#e86767"
            d="M444.523 402.801v20.604a5.153 5.153 0 01-5.151 5.151h-30.905a5.153 5.153 0 005.151-5.151v-20.604a5.153 5.153 0 00-5.151-5.151h30.905a5.152 5.152 0 015.151 5.151z"
          ></path>
          <path
            fill="#90d399"
            d="M165.859 366.744H41.207a5.15 5.15 0 01-5.151-5.151V340.99a5.15 5.15 0 015.151-5.151h124.652a5.15 5.15 0 015.151 5.151v20.604a5.15 5.15 0 01-5.151 5.15z"
          ></path>
          <path
            fill="#70ba70"
            d="M171.01 340.99v20.604a5.153 5.153 0 01-5.151 5.151h-30.905a5.153 5.153 0 005.151-5.151V340.99a5.153 5.153 0 00-5.151-5.151h30.905a5.153 5.153 0 015.151 5.151z"
          ></path>
          <path
            fill="#727272"
            d="M70.567 214.793h-4.121a7.726 7.726 0 110-15.452h4.121a7.725 7.725 0 017.726 7.726 7.725 7.725 0 01-7.726 7.726zM70.567 183.887h-4.121a7.726 7.726 0 110-15.452h4.121c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726zM105.594 214.793h-4.121a7.726 7.726 0 110-15.452h4.121a7.727 7.727 0 010 15.452zM105.594 183.887h-4.121a7.726 7.726 0 110-15.452h4.121a7.726 7.726 0 110 15.452zM140.62 214.793h-4.121a7.726 7.726 0 110-15.452h4.121a7.727 7.727 0 010 15.452zM140.62 183.887h-4.121a7.726 7.726 0 110-15.452h4.121a7.726 7.726 0 110 15.452z"
          ></path>
          <path
            fill="#83c8db"
            d="M365.817 139.323c19.268 10.467 32.348 30.881 32.348 54.351v13.392H274.543v-13.392c0-22.693 12.229-42.529 30.456-53.279 0 0 21.912-10.592 31.355-10.42 9.443.171 29.463 9.348 29.463 9.348z"
          ></path>
          <path
            fill="#56a9b7"
            d="M398.165 193.674v13.392H367.26v-13.392c0-14.33-4.873-27.516-13.063-37.993a41.952 41.952 0 0016.205-13.598c16.73 11.053 27.763 30.04 27.763 51.591z"
          ></path>
          <path
            fill="#fff"
            d="M336.354 159.678c-23.043 0-41.722-18.68-41.722-41.722V78.294h83.445v39.662c-.001 23.042-18.68 41.722-41.723 41.722z"
          ></path>
          <path
            fill="#e0e0e0"
            d="M378.076 78.294v39.662a41.594 41.594 0 01-7.675 24.127 41.945 41.945 0 01-16.205 13.598 41.552 41.552 0 01-17.843 3.997 41.626 41.626 0 01-15.453-2.957c7.531-2.998 13.969-8.128 18.595-14.639a41.411 41.411 0 005.419-10.58 41.52 41.52 0 002.256-13.547V78.294z"
          ></path>
        </svg>
      ),
      link: "/services/#intercom-system",
      description: "",
    },
  ];

  const [mobileMenuState, toggleMobileMenuState] = useState(false);

  var mobileMenuClass = mobileMenuState
    ? "duration-500 ease-out opacity-100 scale-100 block"
    : "duration-500 ease-in opacity-0 scale-95 hidden";

  // const toggleMobileMenu= function(){

  // }

  return (
    <>
      <div
        className="w-screen fixed bg-transparent"
        style={{ zIndex: "100", backdropFilter: "blur(4px)" }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <img className="h-8 w-auto sm:h-10" src={logo} alt />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => toggleMobileMenuState(true)}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10" id="services-menu">
              <Collapsable
                outerClick={outerClick}
                title="Services"
                menuItems={serviceItems}
              />
              <a
                href="/about"
                className="text-base font-medium text-gray-900 hover:text-brandOrange-900"
              >
                About
              </a>
              <a
                href="/contact"
                className="text-base font-medium text-gray-900 hover:text-brandOrange-900"
              >
                Contact
              </a>
              {/*
      'More' flyout menu, show/hide based on flyout menu state.

      Entering: "transition ease-out duration-200"
        From: "opacity-0 translate-y-1"
        To: "opacity-100 translate-y-0"
      Leaving: "transition ease-in duration-150"
        From: "opacity-100 translate-y-0"
        To: "opacity-0 translate-y-1"
    */}
            </nav>
            <div
              className={`hidden md:flex items-center justify-end md:flex-1 lg:w-0`}
            >
              <a
                href="tel:+91-9929297706"
                className="whitespace-nowrap text-base font-medium text-brandOrange-500 hover:text-brandOrange-600"
              >
                +91-9929297706
              </a>
              <a
                href="mailto:8bitsystemsin@gmail.com"
                className="pl-8 whitespace-nowrap font-medium text-brandOrange-500 hover:text-brandOrange-600"
              >
                8bitsystemsin@gmail.com
              </a>
            </div>
          </div>
        </div>
        {/*
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
From: "opacity-0 scale-95"
To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
From: "opacity-100 scale-100"
To: "opacity-0 scale-95"
  */}
        <div
          className={`${mobileMenuClass} absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden`}
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Workflow"
                  />
                </div>
                {/* exit button */}
                <div className="-mr-2">
                  <button
                    type="button"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => toggleMobileMenuState(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    {/* Heroicon name: x */}
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {serviceItems.map((service) => {
                    return (
                      <a
                        key={service.item}
                        href={service.link}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      >
                        {/* {console.log(service.link, service.Svg)} */}
                        {service.Svg}
                        <span className="ml-3 text-base font-medium text-gray-900">
                          {service.title}
                        </span>
                      </a>
                    );
                  })}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a
                  href="/about"
                  className="text-base font-medium text-indigo-700 hover:text-indigo-800"
                >
                  About
                </a>
                <a
                  href="/contact"
                  className="text-base font-medium text-indigo-700 hover:text-indigo-800"
                >
                  Contact
                </a>
              </div>
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a
                  href="tel:+91-9929297706"
                  className="font-medium text-indigo-700 hover:text-indigo-800"
                >
                  +91-9929297706
                </a>

                <a
                  href="mailto:8bitsystemsin@gmail.com"
                  className="font-medium text-indigo-700 hover:text-indigo-800"
                >
                  8bitsystemsin@gmail.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Header.propTypes = {
  outerClick: PropTypes.bool,
};

export default Header;
